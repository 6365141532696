import { EventEmitter, Injectable, inject } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Token } from '../models/token.interface';
import { StorageKeys } from '../models/storage.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/enviroment';
import { APIs } from '@app/core/configs/apis.config';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public onAuthorize: EventEmitter<any> = new EventEmitter<any>();

  localStorageService = inject(LocalStorageService);

  constructor() { }

  getDecodedToken(token: string) {
    return jwtDecode(token);
  }

 removeToken() {
    // this.localStorage.remove(StorageKeys.token);
}

authenticate(token: Token) {
  let decodedToken = this.getDecodedToken(token.accessToken);
  // store data in local storage
  this.localStorageService.set(StorageKeys.refreshToken, token.refreshToken);
  this.localStorageService.set(StorageKeys.token, token.accessToken);
  this.localStorageService.set(StorageKeys.user, JSON.stringify(decodedToken));
  // this.onAuthorize.emit(decodedToken);
}

getNewTokens(tokens: any){
  this.localStorageService.set(StorageKeys.refreshToken, tokens.refreshToken);
  this.localStorageService.set(StorageKeys.token, tokens.accessToken);
}

getRefreshToken() {
  let body = {
      accessToken: this.localStorageService.get(StorageKeys.token),
      refreshToken: this.localStorageService.get(StorageKeys.refreshToken)
  }

  return inject(HttpClient).post(environment.apiUrl + '/' + APIs.Identity.RefreshToken, body);

}
}
