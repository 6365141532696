import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private messageService: MessageService) {}

  alertSucceess(message: string): void {
    this.messageService.add({
      severity: 'success',
      summary: '',
      detail: message,
      life: 3000, // Optional: duration the toast is displayed
      sticky: false, // Optional: prevents closing on click if true
    });
  }

  alertError(title: string, message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: title,
      detail: message,
      life: 3000, // Optional: duration the toast is displayed
      sticky: false, // Optional: prevents closing on click if true
    });
  }
}
